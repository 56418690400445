import { createTheme } from "@mui/material/styles";

const paletteTheme = createTheme({
  palette: {
    primary: {
      main: "#3c5161",
    },
    secondary: {
      main: "#f8bd41",
    },
    info: {
      main: "#a4bcc2",
    },
    light: {
      main: "#E8EEF0",
    },
  },
});

const theme = createTheme(
  {
    typography: {
      fontFamily: ["Open Sans", "Arial", "sans-serif"].join(","),

      h6: {
        fontSize: "16px",
        fontWeight: "400",
      },
    },

    overrides: {
      MuiAppBar: {
        root: {
          textDecoration: "none",
        },
      },
      MuiTableRow: {
        root: {
          textDecoration: "none",
        },
      },
      MuiTableCell: {
        head: {
          fontSize: "1.5rem",
        },
        root: {
          padding: "8px",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: paletteTheme.palette.light.main,
        },
        data: {
          color: paletteTheme.palette.light.contrastText,
        },
        sortActive: {
          color: paletteTheme.palette.light.contrastText,
        },
      },
      MuiTableSortLabel: {
        icon: {
          color: `${paletteTheme.palette.light.contrastText} !important`,
        },
      },
    },
  },
  paletteTheme
);

export default theme;
