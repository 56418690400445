import { Fab, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export const Done = () => (
  <div>
    <Typography variant="h4">
      <Fab aria-label="save" color="success">
        <CheckIcon />
      </Fab>
    </Typography>
  </div>
);
