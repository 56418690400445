import { createGlobalState } from "react-hooks-global-state";
import jwt_decode from "jwt-decode";
import axios from "axios";

const resetAuth = () => {
  console.log("Resetting JWT Token");
  const resetState = { authorised: false, loading: false };
  localStorage.setItem("jwt", JSON.stringify(resetState));
  return resetState;
};

const getAuth = () => {
  const storedJWT = JSON.parse(localStorage.getItem("jwt"));
  if (!storedJWT) {
    return { authorised: false, loading: false };
  }

  if (!storedJWT.jwt) {
    return storedJWT;
  }

  const claims = jwt_decode(storedJWT.jwt);

  const expiryDate = Number(claims.exp) * 1000;
  const timeNow = new Date().getTime();

  console.log(
    `Token expires in ${Math.round((expiryDate - timeNow) / 1000 / 60)} minutes`
  );

  if (timeNow > expiryDate) {
    return resetAuth();
  }

  axios.defaults.headers.common.Authorization = `Bearer ${storedJWT.jwt}`;

  // Refresh the JWT token before it expires
  const whenToReset = expiryDate - timeNow - 60000;

  console.log(
    `Will refresh in in ${Math.round(whenToReset / 1000 / 60)} minutes`
  );

  setTimeout(whenToReset, resetAuth);

  return storedJWT;
};

const { useGlobalState } = createGlobalState({ auth: getAuth() });

const useAuthState = () => {
  const [authState, setAuthStateOriginal] = useGlobalState("auth");

  const setAuthState = (state) => {
    localStorage.setItem("jwt", JSON.stringify(state));
    if (state.authorised) {
      axios.defaults.headers.common.Authorization = `Bearer ${state.jwt}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }

    setAuthStateOriginal(state);
  };

  return [authState, setAuthState];
};

export default useAuthState;
