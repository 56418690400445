import { Amplify } from "aws-amplify";

export const userPoolWebClientId = "1o63daa5klhgqghusrlhlmm42j";

export const ConfigAuth = () => {
  const callback =
    process.env.REACT_APP_AUTH_CALLBACK_URL || "http://localhost:3000";

  Amplify.configure({
    Auth: {
      identityPoolId: "eu-west-1:046abd38-8c12-4ca4-9db7-555f9ec64895",
      region: "eu-west-1",
      userPoolId: "eu-west-1_wFxx3E3ZN",
      userPoolWebClientId,
      mandatorySignIn: false,
      oauth: {
        domain: "data-services.auth.eu-west-1.amazoncognito.com",
        scope: ["email", "profile", "openid"],
        redirectSignIn: callback,
        redirectSignOut: `${callback}/signout`,
        responseType: "code",
      },
    },
  });
};

// see https://dev.to/dabit3/the-complete-guide-to-user-authentication-with-the-amplify-framework-2inh
