import * as React from "react";
import { Grid, ThemeProvider } from "@mui/material";
import theme from "./layout/Theme";
import { ReachAppBar } from "./layout/AppBar";
import { AuthenticatedApp } from "./auth/AuthenticatedApp";
import ProcessSelector from "./components/ProcessSelector";

import "./ImportToolApp.css";

function ImportToolApp() {
  return (
    <ThemeProvider theme={theme}>
      <AuthenticatedApp>
        <ReachAppBar />
        <Grid container>
          <Grid item md={1}></Grid>
          <Grid item md={10}>
            <ProcessSelector />
          </Grid>
          <Grid item md={1}></Grid>
        </Grid>
      </AuthenticatedApp>
    </ThemeProvider>
  );
}

export default ImportToolApp;
