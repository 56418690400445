import {
  DataGrid as MuiDataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const StyledDataGrid = styled(MuiDataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
  "& .total": {
    fontWeight: "bold",
  },
}));

export const DataGrid = ({ columns, rows }) => {
  return (
    <StyledDataGrid
      autoHeight
      density="compact"
      rows={rows.map((row, index) => ({ ...row, id: index }))}
      columns={columns}
      initialState={{
        pagination: {
          pageSize: 10,
        },
      }}
      rowsPerPageOptions={[10, 25, 100, 500]}
      components={{
        Toolbar: CustomToolbar,
      }}
      disableSelectionOnClick
      disableColumnSelector
      getRowClassName={(params) => params.row.style}
    />
  );
};
