import { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid, Progress } from "..";
import { Alert } from "@mui/material";

const renderResponse = (res) => {
  if (res.error) {
    return <Alert severity="error">{res.error}</Alert>;
  }

  const columns = [
    {
      field: "name",
      headerName: "Newsletter Name",
      flex: 1,
    },
    {
      field: "new",
      headerName: "New Subscriptions",
      flex: 1,
    },
    {
      field: "existing",
      headerName: "Existing Subscription",
      flex: 1,
    },
    {
      field: "percentage",
      headerName: "% New",
      flex: 1,
    },
  ];

  const resultColumns = Object.keys(res.fields).map((name) => ({
    field: name,
    headerName: name,
    flex: 1,
  }));

  let totalNew = 0;
  let totalExisting = 0;

  const results = res.lists.map((list) => {
    totalNew += list.new;
    totalExisting += list.existing;

    return {
      ...list,
      percentage: `${Math.round(
        (list.new / (list.new + list.existing)) * 100
      )}%`,
    };
  });

  const overall = Math.round((totalNew / (totalNew + totalExisting)) * 100);

  results.push({
    style: "total",
    name: "Total",
    new: totalNew,
    existing: totalExisting,
    percentage: `${overall}%`,
  });

  return (
    <div>
      Rows in CSV: {res.row_count}
      <br />
      Total Subscriptions: {res.total_subscriptions}
      <br />
      <h2>Newsletter Subscriptions</h2>
      <DataGrid rows={results} columns={columns} />
      <br />
      <h2>New Subscriptions to Submit</h2>
      <DataGrid rows={res.results} columns={resultColumns} />
    </div>
  );
};

export const CheckSubscriptions = ({ selectedFile, onSetResults }) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (!selectedFile) {
      setLoading(false);
      setResponse({ error: "Please select a file" });
      onSetResults([]);
      return;
    }

    const formData = new FormData();
    formData.append("upload", selectedFile, selectedFile.name);

    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_API}/checkSubscriptions`, formData)
      .then((response) => response.data)
      .then((data) => {
        setResponse(data);
        onSetResults({ toLoad: data.results, lists: data.lists });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        onSetResults([]);
        setResponse({ error: error.message });
      });
  }, [selectedFile, onSetResults]);

  if (loading)
    return (
      <Progress text="Running Subscription Status Checks (Please get coffee at we run around 70 checks/second)" />
    );

  return response ? renderResponse(response) : "";
};
