import { useEffect, useState } from "react";
import axios from "axios";
import { Alert, Fab, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Progress } from "..";

const renderResponse = (res) => {
  if (res.error) {
    return <Alert severity="error">{res.error}</Alert>;
  }

  return (
    <div>
      <Typography variant="h4">
        <Fab aria-label="save" color="success">
          <CheckIcon />
        </Fab>
        &nbsp; &nbsp; Submitting {res.subscriptions} subscriptions. You will
        receive two emails. One confirming when all subscriptions have been sent
        to the queue, and one when when they have been actioned.
      </Typography>
    </div>
  );
};

export const SubmitSubscriptions = ({ subscriptions }) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (!subscriptions) {
      setLoading(false);
      setResponse({ error: "Please select a file" });
      return;
    }

    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/submitSubscriptions`,
        subscriptions
      )
      .then((response) => response.data)
      .then((data) => {
        setResponse(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setResponse({ error: error.message });
      });
  }, [subscriptions]);

  if (loading) return <Progress text="Submitting subscriptions..." />;

  return response ? renderResponse(response) : "";
};
