import { useEffect, useState } from "react";
import axios from "axios";
import { Alert, Typography } from "@mui/material";

const renderResponse = (response) => {
  if (response.error) {
    return <Alert severity="error">{response.error}</Alert>;
  }

  return (
    <div>
      <Typography variant="h4">
        You have submitted an erasure request for {response.detail.email}, once
        completed notifications will be sent for each system which has
        responded.
      </Typography>
      <br />
      Ref: {response.detail.externalAuditIdentifier}
    </div>
  );
};

export const SubmitRTBF = ({ email, externalAuditIdentifier }) => {
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (!email) {
      setResponse({ error: "Please enter an email" });
      return;
    }

    axios
      .post(`${process.env.REACT_APP_BACKEND_API}/submit-rtbf`, {
        email,
        externalAuditIdentifier,
      })
      .then((response) => response.data)
      .then((data) => {
        setResponse(data);
      })
      .catch((error) => {
        setResponse({ error: error.message });
      });
  }, [email, externalAuditIdentifier]);

  return response ? renderResponse(response) : "";
};
