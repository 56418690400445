import { Alert, Typography } from "@mui/material";
import { useState } from "react";
import useAuthState from "../auth/useAuthState";
import Stepper from "./Stepper";
import {
  SelectFile,
  DetectColumns,
  DetectSubscriptions,
  CheckSubscriptions,
  SubmitSubscriptions,
  Done,
} from "./steps";

const BulkImportProcess = () => {
  const [auth] = useAuthState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);

  const canSubscribe = auth.claims["cognito:groups"].includes("pii_user");

  const stepLabels = canSubscribe
    ? [
        "Select the File",
        "Detect Columns",
        "Detect Subscriptions",
        "Check Subscription Status",
        "Done",
      ]
    : ["Select the File", "Detect Columns", "Done"];

  const nextLabels = canSubscribe
    ? [
        "Detect Columns",
        "Detect Subscriptions",
        "Check Subscription Status",
        "Do it!",
        "Done",
      ]
    : ["Detect Columns", "Detect Subscriptions", "Done"];

  return (
    <>
      <Typography variant="h5">
        Newsletter Bulk Subscription Tool allows you to upload a number of
        subscriptions from a CSV file.
      </Typography>
      {canSubscribe || (
        <Alert severity="warning">
          You do not have permission to access any PII information, nor to
          import any subscriptions. If you believe that you need to have access
          to this, please contact either the CRM Data & Ops manager or the Data
          Governance manager.
        </Alert>
      )}
      <br />
      <Stepper stepLabels={stepLabels} nextLabels={nextLabels}>
        <SelectFile onChange={setSelectedFile} selectedFile={selectedFile} />
        <DetectColumns selectedFile={selectedFile} />
        {canSubscribe && <DetectSubscriptions selectedFile={selectedFile} />}
        {canSubscribe && (
          <CheckSubscriptions
            selectedFile={selectedFile}
            onSetResults={setSubscriptions}
          />
        )}
        {canSubscribe && <SubmitSubscriptions subscriptions={subscriptions} />}
        <Done />
      </Stepper>
    </>
  );
};

export default BulkImportProcess;
