import * as React from "react";
import jwt_decode from "jwt-decode";
import useAuthState from "./useAuthState";
import { Auth } from "aws-amplify";
import { ConfigAuth, userPoolWebClientId } from "./AmplifyAuth";
import { Box, Button, CircularProgress } from "@mui/material";
import { ReachLogout } from "../layout/Logout";

ConfigAuth();

export const LoggingInComponent = (props) => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{ height: "100vh" }}
      >
        <CircularProgress />
        <p>Please wait... Checking Permissions</p>

        <Button
          variant="outlined"
          sx={{ width: "300px" }}
          onClick={props.retry}
        >
          Retry Check
        </Button>
      </Box>
    </>
  );
};

export const ProblemOccurredComponent = ({ error }) => (
  <>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{ height: "100vh" }}
    >
      <p>A problem occurred when checking permissions.</p>
      <p>Error: '{error && error.toString()}'.</p>
      <ReachLogout />
    </Box>
  </>
);

export const AuthenticatedApp = (props) => {
  const [error, setError] = React.useState();
  const [auth, setAuth] = useAuthState();

  const retry = () => {
    setAuth({
      authorised: false,
      loading: false,
    });
  };

  const isNotAuthenticated = !auth.authorised && !auth.loading && !auth.errored;
  if (isNotAuthenticated) {
    Auth.federatedSignIn({
      provider: "Google",
    }).then(() => {
      setAuth({
        authorised: false,
        loading: true,
      });
    });
  }

  const isAuthenticating = auth.loading;
  if (isAuthenticating) {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const username = user.username;
        const token =
          user.pool.storage[
            `CognitoIdentityServiceProvider.${userPoolWebClientId}.${username}.idToken`
          ];

        const value = {
          authorised: true,
          loading: false,
          jwt: token,
          claims: jwt_decode(token),
        };
        setAuth(value);
      })
      .catch((e) => {
        setError(e);
      });
  }

  const isAuthenticated = auth.authorised;
  if (isAuthenticated) {
    return props.children;
  }

  if (isAuthenticating) {
    return <LoggingInComponent retry={retry} />;
  }

  return <ProblemOccurredComponent error={error} />;
};
