import { Alert, Button } from "@mui/material";

export const SelectFile = ({ selectedFile, onChange }) => {
  const onFileChange = (event) => {
    onChange(event.target.files[0]);
  };

  return (
    <div>
      <Button variant="contained" component="label">
        Please select file to use
        <input type="file" onChange={onFileChange} hidden accept=".csv" />
      </Button>

      {selectedFile && (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>
          <p>Last Modified: {selectedFile.lastModifiedDate.toDateString()}</p>
        </div>
      )}

      {selectedFile && !selectedFile.name.endsWith(".csv") && (
        <Alert severity="warning">
          Please note that <b>only CSV</b>, not Excel files, can be used for
          this import
        </Alert>
      )}
    </div>
  );
};
