import * as React from "react";
import useAuthState from "../auth/useAuthState";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, Tooltip } from "@mui/material";

export const ReachLogout = () => {
  const [, setAuth] = useAuthState();

  const handleClick = async () => {
    setAuth({ authorised: false, loading: false });
  };
  return (
    <Tooltip title="Refresh Permissions" enterDelay={500}>
      <IconButton color="inherit" onClick={handleClick}>
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ReachLogout;
