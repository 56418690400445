import { Typography } from "@mui/material";
import { useState } from "react";
import Stepper from "./Stepper";
import { SelectFile, DetectRemovals, SubmitRemovals, Done } from "./steps";

const stepLabels = ["Select the File", "Detect Removals", "Done"];
const nextLabels = ["Detect Removals", "Do it!", "Done"];

const BulkRemoveProcess = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  return (
    <>
      <Typography variant="h5">
        Newsletter Bulk Unsubscribe Tool, this allows you remove a number of
        subscriptions from a CSV file.
      </Typography>
      <br />
      <Stepper
        stepLabels={stepLabels}
        nextLabels={nextLabels}
        sx={{ backgroundColor: "#ef535045" }}
      >
        <SelectFile onChange={setSelectedFile} selectedFile={selectedFile} />
        <DetectRemovals selectedFile={selectedFile} />
        <SubmitRemovals selectedFile={selectedFile} />
        <Done />
      </Stepper>
    </>
  );
};

export default BulkRemoveProcess;
