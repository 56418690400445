import { TextField } from "@mui/material";

export const EnterEmail = ({
  email,
  reference,
  onChangeEmail,
  onChangeReference,
}) => {
  return (
    <>
      <TextField
        fullWidth
        required
        label="Email Address to be Forgotten"
        onChange={(event) => {
          onChangeEmail(event.target.value);
        }}
        value={email}
      />
      <br />
      <br />
      <TextField
        fullWidth
        required
        label="RTBF Reference"
        onChange={(event) => {
          onChangeReference(event.target.value);
        }}
        value={reference}
      />
    </>
  );
};
