import { Typography } from "@mui/material";
import { useState } from "react";
import Stepper from "./Stepper";
import { Done } from "./steps";
import { EnterEmail } from "./steps/EnterEmail";
import { SubmitRTBF } from "./steps/SubmitRTBF";

const stepLabels = ["Enter Email", "Submit"];
const nextLabels = ["Submit Request", "Done"];

const RTBFRequest = () => {
  const [email, setEmail] = useState(null);
  const [ref, setRef] = useState(null);

  return (
    <>
      <Typography variant="h5">
        Submit an RTBF request which will remove or redact any PII data for the
        email address in Data Services services
      </Typography>
      <br />
      <Stepper stepLabels={stepLabels} nextLabels={nextLabels}>
        <EnterEmail
          onChangeEmail={setEmail}
          email={email}
          onChangeReference={setRef}
          reference={ref}
        />
        <SubmitRTBF email={email} externalAuditIdentifier={ref} />
        <Done />
      </Stepper>
    </>
  );
};

export default RTBFRequest;
