import { AppBar, Toolbar, Typography, Grid, Avatar } from "@mui/material";
import useAuthState from "../auth/useAuthState";
import ReachLogout from "./Logout";

export const ReachAppBar = () => {
  const [authState] = useAuthState();

  return (
    <AppBar position="static">
      <Toolbar>
        <img
          height="45px"
          src="https://jobs.reachplc.com/application/themes/trmrjobs1701/assets/img/brand_logo_reach.svg"
          alt="Reach Logo"
        />
        <Grid container>
          <Grid item md={1}></Grid>
          <Grid item md={6}>
            <Typography variant="h6">
              Newsletter Bulk Subscription Tool
            </Typography>
          </Grid>
          <Grid item md={5}>
            {authState.authorised && (
              <Grid container justifyContent="right">
                <Grid item>
                  <Avatar src={authState?.claims?.picture} />
                </Grid>
                <Grid item>
                  {authState?.claims?.name}
                  <br />
                  <small>{authState?.claims?.email}</small>
                </Grid>
                <Grid item>
                  <ReachLogout />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
