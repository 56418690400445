import { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid, Progress } from "..";
import { Alert } from "@mui/material";

const renderResponse = (res) => {
  if (res.error) {
    return <Alert severity="error">{res.error}</Alert>;
  }

  const columns = [
    {
      field: "name",
      headerName: "Newsletter Name",
      flex: 1,
    },
    {
      field: "total",
      headerName: "Subscriptions",
      flex: 1,
    },
  ];

  return (
    <div>
      Rows in CSV: <b>{res.row_count}</b>
      <br />
      Total Subscriptions: <b>{res.total_subscriptions}</b>
      <br />
      Rows without a subscription: <b>{res.total_without_list}</b>
      <br />
      <h2>Detected Subscriptions</h2>
      <DataGrid rows={res.lists} columns={columns} />
    </div>
  );
};

export const DetectSubscriptions = ({ selectedFile }) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (!selectedFile) {
      setLoading(false);
      setResponse({ error: "Please select a file" });
      return;
    }

    const formData = new FormData();
    formData.append("upload", selectedFile, selectedFile.name);

    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_API}/detectSubscriptions`,
        formData
      )
      .then((response) => response.data)
      .then((data) => {
        setResponse(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setResponse({ error: error.message });
      });
  }, [selectedFile]);

  if (loading) return <Progress text="Running Subscription Detection" />;

  return response ? renderResponse(response) : "";
};
