import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import BulkImportProcess from "./BulkImportProcess";
import BulkRemoveProcess from "./BulkRemoveProcess";
import RTBFRequest from "./RTBFRequest";
import useAuthState from "../auth/useAuthState";
import BulkUpdateProcess from "./BulkUpdateProcess";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProcessSelector = (props) => {
  const [value, setValue] = React.useState(0);
  const [auth] = useAuthState();

  const canUnsubscribe =
    !!auth.claims["cognito:groups"].includes("pii_user_remove");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Import Subscriptions" {...a11yProps(0)} />
            <Tab label="Update Subscriptions" {...a11yProps(1)} />
            {canUnsubscribe && (
              <Tab label="Import Subscription Removals" {...a11yProps(2)} />
            )}
            {canUnsubscribe && (
              <Tab label="RTBF Request Submission" {...a11yProps(3)} />
            )}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <BulkImportProcess />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BulkUpdateProcess />
        </TabPanel>
        {canUnsubscribe && (
          <TabPanel value={value} index={2}>
            <BulkRemoveProcess />
          </TabPanel>
        )}
        {canUnsubscribe && (
          <TabPanel value={value} index={3}>
            <RTBFRequest />
          </TabPanel>
        )}
      </Box>
    </>
  );
};

export default ProcessSelector;
